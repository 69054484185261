<!-- VUETIFY2 - NATIVE -->
<template>
    <base-layout headerSize="50px" datatableExcludedSize="300px">
        <template slot="header">
            <h1>Répartition de l'intéressement</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container fluid>
                <v-row justify="space-between">
                    <v-col md="5" lg="5" xl="2">
                        <v-text-field v-model="exercice.libelle" :disabled="true" label="Exercice"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="2">
                        <v-formatted-number-text-field v-model="exercice.inte_exercice.montant_interessement"
                                                       prefixValue="€" :reverseValue="true"
                                                       :disabledValue="true"
                                                       labelValue="Intéressement"/>
                    </v-col>
                    <v-col md="5" lg="5" xl="2">
                        <v-formatted-number-text-field v-model="tauxFiscalite.taux_csg_crds" prefixValue="%"
                                                       :reverseValue="true" :disabledValue="true"
                                                       labelValue="CSG - CRDS"/>
                    </v-col>
                </v-row>
                <v-datatable-repartition-inte @update-repartitions="repartitions = $event"
                                              @update-repartition-uniforme-active="repartitionUniformeActive = $event"
                                              @update-repartition-presence-active="repartitionPresenceActive = $event"
                                              @update-repartition-salaire-active="repartitionSalaireActive = $event"
                                              @update-nombre-distributions="nombreDistributions = $event"
                                              @update-detail-calcul="detailCalcul = $event"
                                              :repartitions="repartitions"
                                              :repartitionUniformeActive="repartitionUniformeActive"
                                              :repartitionPresenceActive="repartitionPresenceActive"
                                              :repartitionSalaireActive="repartitionSalaireActive"
                                              :nombreDistributions="nombreDistributions"
                                              :detailCalcul="detailCalcul"
                                              :selectedExerciceId="exercice.id"
                                              :height="datatableSize"
                                              ref="datatableRepartitionInte"
                />
            </v-container>
        </template>
        <template slot="footer">
            <v-row dense>
                <!-- Petit bouton -->
                <v-col md="2" lg="2" xl="2">
                    <v-btn dark color="primary" outlined @click="downloadExcel" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <v-col md="2" lg="2" xl="2" v-if="!exercice.reprise">
                    <detail-repartition-inte :explain="detailCalcul"/>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2" v-if="exercice.inte_exercice.is_repartition_inte_valide">
                   <v-popup-asynchrone
                        documentsNamePlural="Fiches individuelles"
                        :isDocumentGenderFeminine="true"
                        documentsFullNamePlural="Fiches individuelles d'intéressement"
                        documentPdfName="fiches_individuelles_interessement"
                        :getStateDocuments="getStateFichesIndividuelles"
                        :generateDocuments="generateFichesIndividuelles"
                        :downloadDocuments="downloadFichesIndividuelles"
                    />
                </v-col>
                
                <v-spacer/>
                <template v-if="!(exercice.inte_exercice.is_repartition_inte_valide)">
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-btn block :to="{name: 'inte-exercices'}">Annuler</v-btn>
                    </v-col>
                    <!-- Petit bouton -->
                    <v-col md="3" lg="3" xl="2">
                        <v-custom-confirmation-dialog :message="confirmationMessage"
                                                      justifyMessage="end"
                                                      openButtonMessage="Valider"
                                                      title="Valider la répartition de l'intéressement"
                                                      :isIcon="false"
                                                      @action-confirmee="validateRepartitionInte()"
                                                      maxWidth="1200px"
                        >
                            <template slot="custom-infos">
                                <h3>Récapitulatif des cas exceptionnels présents dans cette répartition</h3>
                                <v-tabs v-model="recapValidationCurrentTab" grow>
                                    <v-tab key="exclusAnciennete">
                                        Seuil d'ancienneté non atteint ({{ repartitionsExcluesNonForce.length }})
                                    </v-tab>
                                    <v-tab key="plafondAtteint">
                                        Plafond atteint ({{ repartitionsPlafondAtteint.length }})
                                    </v-tab>
                                    <v-tab key="exclusForce">
                                        Exclu(e)s manuellement ({{ repartitionsExcluesForce.length }})
                                    </v-tab>
                                    <v-tab key="inclusForce">
                                        Inclu(e)s manuellement ({{ repartitionsIncluesForce.length }})
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="recapValidationCurrentTab">
                                    <v-tab-item key="exclusAnciennete">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsExcluesNonForce"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Tous(tes) les bénéficiaires ont atteint le seuil d'ancienneté"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                    <v-tab-item key="plafondAtteint">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsPlafondAtteint"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'a atteint son plafond"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                    <v-tab-item key="exclusForce">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsExcluesForce"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'est manuellement exclu(e)"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                    <v-tab-item key="inclusForce">
                                        <v-row justify="center"><v-col cols="8">
                                            <v-data-table :items="repartitionsIncluesForce"
                                                          :headers="recapValidationHeaders"
                                                          :options.sync="recapValidationPagination"
                                                          no-data-text="Aucun(e) bénéficiaire n'est manuellement inclu(e)"
                                                          dense/>
                                        </v-col></v-row>
                                    </v-tab-item>
                                </v-tabs-items>
                            </template>
                        </v-custom-confirmation-dialog>
                    </v-col>
                </template>
                <!-- Petit bouton -->
                <v-col v-else md="2" lg="2" xl="2">
                    <v-btn block :to="{name: 'inte-exercices'}">Retour</v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import XLSX from "xlsx";
    import BaseLayout from "@/components/BaseLayout";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import DetailRepartitionInte from "@/components/repartitionInte/VDetailRepartitionInte";
    import Utils from "@/utils";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import VDatatableRepartitionInte from '@/components/repartitionInte/VDatatableRepartitionInte.vue';
    import VPopupAsynchrone from "@/components/VPopupAsynchrone.vue";
    /**
     * Composant affichant la datatable de la répartition de l'intéressement
     * pour un exercice donné
     * @displayName Intéressement - RepartitionInte
     */
    export default {
        components: {
            BaseLayout,
            loadingScreen,
            VFormattedNumberTextField,
            DetailRepartitionInte,
            VCustomConfirmationDialog,
            VDatatableRepartitionInte,
            VPopupAsynchrone,
        },
        data() {
            return {
                repartitions: [],
                repartitionUniformeActive: false,
                repartitionPresenceActive: false,
                repartitionSalaireActive: false,
                nombreDistributions: 1,
                confirmationMessage: "Une fois la répartition de l'intéressement validée, les montants ne seront plus modifiables." +
                    "<br/>Confirmez-vous cette opération ?",
                detailDialog: false,
                detailCalcul: {},
                tauxFiscalite: {},
                exercice: {
                    inte_exercice: {
                        reprise: true,
                    },
                },
                recapValidationHeaders: [
                    {
                        text: "Bénéficiaire",
                        value: "beneficiaire_name",
                    },
                    {
                        text: "Numéro de tiers",
                        value: "numero_tiers",
                    },
                ],
                recapValidationPagination: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ["beneficiaire_name", "numero_tiers"],
                    sortDesc: [false, false]
                },
                recapValidationCurrentTab: null,
                
            };
        },
        mounted() {
            const {inteExerciceId} = this.$route.params;
            this.getInteExerciceById(inteExerciceId).then(result => {
                this.getTauxFiscaliteByDate();
            });
        },
        computed: {
            repartitionsExcluesForce() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionInte.isBeneficiaireExcluForce(repartition)
                });
            },
            repartitionsIncluesForce() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionInte.isBeneficiaireIncluForce(repartition)
                });
            },
            repartitionsExcluesNonForce() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionInte.isBeneficiaireExcluNonForce(repartition)
                });
            },
            repartitionsPlafondAtteint() {
                return this.repartitions.filter(repartition => {
                    return this.$refs.datatableRepartitionInte.isBeneficiairePlafondAtteint(repartition)
                });
            },
        },
        methods: {
            getInteExerciceById(inteExerciceId) {
                return internalApi.inteExercice.getById(inteExerciceId).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            /**
             *  Récupère les taux de fiscalité valables à la date de fin de l'exercice
             */
            getTauxFiscaliteByDate() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.tauxFiscalite.getTauxFiscaliteByDate(this.exercice.date_fin)
                    .then(result => {
                        this.tauxFiscalite = result;
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Helper pour télécharger le excel
             */
            downloadExcel: function () {
                const ws = XLSX.utils.aoa_to_sheet(this.getDataExcel());
                const wb = XLSX.utils.book_new();
                wb.props = {
                    Title: "Répartition Intéressement",
                    Subject: "Répartition de l'intéressement",
                    Author: "EZIScop",
                    CreatedDate: Date.now()
                };
                XLSX.utils.book_append_sheet(wb, ws, "Répartition");
                XLSX.writeFile(wb, "repartition_interessement.xlsx");
            },
            /**
             * Récupère le contenu à exporter en excel
             */
            getDataExcel() {
                const headers = this.buildHeadersForExcel();
                const rows = this.buildRowsForExcel();

                return _.concat(headers, rows);
            },
            /**
             * Met en forme les headers de la datatable pour le fichier excel
             * @returns {string[][]}
             */
            buildHeadersForExcel() {

                let headers = ["Bénéficiaire", "Numéro de tiers", "Courriel"];

                if (this.repartitionUniformeActive) {
                    headers.push("Répartition uniformisée");
                }
                if (this.repartitionSalaireActive) {
                    headers.push("Répartition / Salaires");
                }
                if (this.repartitionPresenceActive) {
                    headers.push("Répartition / Temps de présence");
                }

                _.forEach(_.range(this.nombreDistributions - 1), () => {
                    headers = headers.concat(["Total", "Reliquat individuel"]);
                });

                headers = headers.concat(["Total brut", "CSG - CRDS", "Total net"]);
                return [headers];
            },
            /**
             * Met en forme les lignes de la datatable pour le excel
             * @returns {Array}
             */
            buildRowsForExcel() {
                let rows = [];
                _.forEach(this.repartitions, (repBenef) => {
                    let row = [repBenef.beneficiaire_name, repBenef.numero_tiers, repBenef.email];
                    _.forEach(repBenef.distributions, (distrib, index) => {
                        if (index === 0) {
                            if (this.repartitionUniformeActive) {
                                row.push(distrib.montant_uniforme);
                            }
                            if (this.repartitionSalaireActive) {
                                row.push(distrib.montant_salaire);
                            }
                            if (this.repartitionPresenceActive) {
                                row.push(distrib.montant_presence);
                            }
                            if (this.nombreDistributions > 1) {
                                row.push(distrib.total);
                                row.push(distrib.reliquat);
                            }
                        } else if (index < this.nombreDistributions - 1) {
                            row.push(distrib.cumul);
                            row.push(distrib.reliquat);
                        }
                    });

                    row.push(repBenef.total_brut);
                    row.push(repBenef.montant_csg_crds);
                    row.push(repBenef.total_net);

                    rows.push(row);
                });

                return rows;
            },
            /**
             * Valide la répartition de la participation affichée dans la datatable puis reviens
             * à l'écran des exercices
             */
            validateRepartitionInte() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                internalApi.repartitionInte.validateInteRepartitionByInteExerciceId(this.exercice.id)
                .then(res => {
                    this.$refs.loadingComponent.resetLoading();
                    this.$router.push({
                        name: "inte-exercices"
                    });
                }).catch((error) => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            getStateFichesIndividuelles(){
                return internalApi.repartitionInte.getStateFichesIndividuellesInte(this.exercice.inte_exercice.id);
            },
            /**
             * Lance la génération des fiches individuelles
             */
            generateFichesIndividuelles(){
                return internalApi.repartitionInte.generateFichesIndividuellesInte(this.exercice.inte_exercice.id);
            },
            /** Télécharge les fiches individuelles */
            downloadFichesIndividuelles(){
                return internalApi.repartitionInte.getFichesIndividuellesInte(this.exercice.inte_exercice.id);
            },
        }
    }
</script>
